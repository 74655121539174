<template>
  <Content>
    <template #contentBody>
      <a-row>
        <a-col :span="24">
          <a-steps :current="0" style="max-width: 1800px" size="small">
            <a-step :title="$t('warehouse.set_out_plan')" />
            <a-step :title="$t('warehouse.add_shipment')" />
            <a-step :title="$t('warehouse.confirm_out_plan')" />
          </a-steps>
        </a-col>
      </a-row>
      <div class="mt-3">
        <a-form ref="refForm" :rules="rules" :model="data">
          <!-- 库存所在仓 -->
          <a-row>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                name="warehouseId"
                :label="$t('warehouse.inventory_affiliation_warehouse')"
              >
                <a-select
                  v-model:value="data.warehouseId"
                  @change="handelChangeInventoryWarehouse"
                  :placeholder="$t('warehouse.out_warehouse')"
                  allow-clear
                  :showSearch="true"
                  :loading="dropdownData.inventoryWarehouseLoading"
                  optionFilterProp="search-key"
                >
                  <a-select-option
                    v-for="(value, key) in dropdownData.inventoryWarehouses"
                    :key="key"
                    :warehouseAddress="value"
                    :value="value?.id"
                    :title="`${value.warehouseNo}(${value.warehouseName})`"
                    :search-key="value.warehouseNo + value.warehouseName"
                    :timezone="value?.timezone"
                  >
                    {{ value.warehouseNo }}({{ value.warehouseName }})
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- 库存仓库地址 -->
          <a-row>
            <a-col v-if="data.warehouseId" :span="24">
              <a-form-item
                :label="$t('warehouse.warehouse_address')"
                :label-col="{
                  sm: { span: 6 },
                  xl: { span: 3 },
                  xxl: { span: 2 },
                }"
                :wrapper-col="{
                  sm: { span: 18 },
                  xl: { span: 21 },
                  xxl: { span: 22 },
                }"
              >
                {{ $t("warehouse.warehouse_address") }}
                {{ getAddress(data.inventoryWarehouseAddress) }}
                <span v-if="data.inventoryWarehouseAddress?.linkMan"
                  >({{ getLinkman(data.inventoryWarehouseAddress) }})</span
                >
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 出库类型 -->
          <a-row>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                name="destinationType"
                :label="$t('warehouse.out_warehouse_type')"
              >
                <a-select
                  v-model:value="data.destinationType"
                  @change="handelChangeOutType"
                  :placeholder="$t('common.please_select')"
                >
                  <a-select-option
                    v-for="(value, key, index) in destinationTypeEnum"
                    :key="key"
                    :value="value"
                    :data-index="index"
                  >
                    {{ $t($enumLangkey("destinationType", value)) }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 赛易仓库类型 -->
          <a-row v-if="data.destinationType == destinationTypeEnum.SeWarehouse">
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                name="warehouseType"
                :label="$t('warehouse.warehouse_type')"
              >
                <a-select
                  v-model:value="data.warehouseType"
                  @change="handelChangeSeWarehouseType"
                  :placeholder="$t('common.please_select')"
                >
                  <a-select-option :value="warehouseTypeEnum.storageWarehouse">
                    {{
                      $t(
                        $enumLangkey(
                          "warehouseType",
                          warehouseTypeEnum.storageWarehouse
                        )
                      )
                    }}
                  </a-select-option>
                  <a-select-option
                    :value="warehouseTypeEnum.consignmentWarehouse"
                  >
                    {{
                      $t(
                        $enumLangkey(
                          "warehouseType",
                          warehouseTypeEnum.consignmentWarehouse
                        )
                      )
                    }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <!--赛易 的仓库代码  -->
          <a-row v-if="data.warehouseType">
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                name="toWarehouseId"
                :label="$t('warehouse.se_warehouse_code')"
              >
                <a-select
                  v-model:value="data.toWarehouseId"
                  @change="handelChangeToWarehouse"
                  :placeholder="$t('common.please_select')"
                  :loading="dropdownData.targetWarehousesLoading"
                  show-search
                  optionFilterProp="search-key"
                >
                  <a-select-option
                    v-for="item in dropdownData.targetWarehouses"
                    :key="item.id"
                    :value="item.id"
                    :warehouseAddress="item"
                    :search-key="item.warehouseNo + item.warehouseName"
                  >
                    {{ item.warehouseNo }} ({{ item.warehouseName }})
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <!--FBA亚马逊 的仓库代码  -->
          <a-row v-if="data.destinationType == destinationTypeEnum.FBA">
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                name="toWarehouseId"
                :label="$t('warehouse.fba_warehouse_code')"
              >
                <a-select
                  v-model:value="data.toWarehouseId"
                  optionFilterProp="search-key"
                  :loading="dropdownData.targetWarehousesLoading"
                  show-search
                  @change="handelChangeToWarehouse"
                  :placeholder="$t('common.please_select')"
                >
                  <a-select-option
                    v-for="item in dropdownData.targetWarehouses"
                    :title="item.name"
                    :search-key="item.name"
                    :warehouseAddress="item"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <!--美客多 的仓库代码  -->
          <a-row
            v-if="data.destinationType == destinationTypeEnum.mercadolibre"
          >
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                name="toWarehouseId"
                :label="$t('warehouse.mercado_libre_warehouse_code')"
              >
                <a-select
                  v-model:value="data.toWarehouseId"
                  @change="handelChangeToWarehouse"
                  :placeholder="$t('common.please_select')"
                  :loading="dropdownData.targetWarehousesLoading"
                  show-search
                  optionFilterProp="search-key"
                >
                  <a-select-option
                    v-for="item in dropdownData.targetWarehouses"
                    :key="item.id"
                    :value="item.id"
                    :warehouseAddress="item"
                    :search-key="item.name"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 目标仓库的地址 -->
          <a-row
            v-if="
              data.toWarehouseId &&
              data.targetAddress &&
              (data.destinationType == destinationTypeEnum.SeWarehouse ||
                data.destinationType == destinationTypeEnum.FBA ||
                data.destinationType == destinationTypeEnum.mercadolibre)
            "
          >
            <a-col span="24">
              <a-form-item
                :label-col="{
                  sm: { span: 6 },
                  xl: { span: 3 },
                  xxl: { span: 2 },
                }"
                :wrapper-col="{
                  sm: { span: 18 },
                  xl: { span: 21 },
                  xxl: { span: 22 },
                }"
                :label="$t('warehouse.transport_goods_address')"
              >
                {{ getAddress(data.targetAddress) }}
                <span v-if="data.targetAddress?.linkMan">
                  ({{ getLinkman(data.targetAddress) }})
                </span>
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 其他地址 开始 -->
          <a-row
            v-if="data.destinationType == destinationTypeEnum.OtherAddress"
          >
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                :label="$t('warehouse.common_address')"
              >
                <a-select
                  v-model:value="data.address.id"
                  @change="handleChangeCommonAddress"
                  :loading="dropdownData.commonAddressLoading"
                  :allowClear="true"
                  show-search
                  optionFilterProp="search-key"
                  :placeholder="$t('warehouse.selected_common_address')"
                >
                  <a-select-option
                    v-for="item in dropdownData.commonAddress"
                    :key="item.id"
                    :value="item.id"
                    :search-key="item.contactName"
                    :title="item.contactName"
                    :option="item"
                  >
                    {{ item.contactName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-if="data.destinationType === destinationTypeEnum.OtherAddress">
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label="$t('warehouse.destination')"
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                :name="['address', 'linkMan']"
              >
                <a-input :maxlength="35" v-model:value="data.address.linkMan" v-user-name-filter />
              </a-form-item>
            </a-col>

            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label="$t('warehouse.phone')"
                :name="['address', 'linkManPhone']"
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
              >
                <a-input-group style="display: flex" compact>
                  <a-select
                    style="width: 100px"
                    v-model:value="data.address.phoneCountryId"
                    :placeholder="$t('logistics.area_code')"
                    :loading="dropdownData.phoneCountryLoading"
                    option-label-prop="label"
                    optionFilterProp="search-key"
                    show-search
                    @change="handleChangePhoneCountryCode"
                  >
                    <a-select-option
                      v-for="(item, index) in dropdownData.phoneCountryList"
                      :key="index"
                      :value="item.id"
                      :label="`+${item.telCode}`"
                      :code="item.telCode"
                      :search-key="
                        item.ioS2 +
                        item.cnName +
                        item.enName +
                        '+' +
                        item.telCode
                      "
                      :title="`+${item.telCode}(${getLanguageName(item)})`"
                      >+{{ item.telCode }}({{
                        getLanguageName(item)
                      }})</a-select-option
                    >
                  </a-select>
                  <a-input
                    style="flex: 1"
                    v-model:value="data.address.linkManPhone"
                    :placeholder="$t('warehouse.phone')"
                    @change="handleChangePhone"
                  />
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            v-if="data.destinationType == destinationTypeEnum.OtherAddress"
          >
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label="$t('common.postcode')"
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                :name="['address', 'postCode']"
              >
                <a-input v-model:value="data.address.postCode" />
              </a-form-item>
            </a-col>

            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24, offset: 6 }"
              :xl="{ span: 9, offset: 3 }"
              :xxl="{ span: 8, offset: 2 }"
            >
              <a-form-item>
                <a-button
                  :loading="data.saveCommonAddressLoading"
                  @click="handlePostCodeQuery"
                  size="small"
                >
                  {{ $t("waybill.check_the_postcode") }}
                </a-button>
                <CPostQuery
                  ref="postCodeQueryRef"
                  v-model:visible="postCodeQueryData.visible"
                  :postCode="data.address.postCode"
                  @onSelected="handleOnPostQuerySelected"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            v-if="data.destinationType == destinationTypeEnum.OtherAddress"
          >
            <a-col :span="24">
              <a-form-item
                :label-col="{
                  sm: { span: 6 },
                  xl: { span: 3 },
                  xxl: { span: 2 },
                }"
                :wrapper-col="{
                  sm: { span: 18 },
                  xl: { span: 21 },
                  xxl: { span: 22 },
                }"
                :autoLink="false"
              >
                <template #label>
                  {{ $t("warehouse.receiving_address") }}
                </template>
                <a-row :gutter="16">
                  <a-col :span="24" :sm="8" :md="6" :xl="4" :xxl="3">
                    <a-form-item :name="['address', 'countryId']" class="mb-0">
                      <a-select
                        v-model:value="data.address.countryId"
                        :allowClear="true"
                        show-search
                        optionFilterProp="search-key"
                        @change="handleChangeCountry"
                        class="mr-2"
                        :loading="dropdownData.countryLoading"
                        :placeholder="$t('common.country')"
                      >
                        <a-select-option
                          v-for="item in dropdownData.country"
                          :key="item.id"
                          :title="getLanguageName(item) + '(' + item.ioS2 + ')'"
                          :search-key="item.cnName + item.enName + item.ioS2"
                        >
                          {{ getLanguageName(item) }}({{ item.ioS2 }})
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="24" :sm="8" :md="6" :xl="4" :xxl="3">
                    <a-form-item :name="['address', 'provinceId']" class="mb-0">
                      <a-select
                        v-model:value="data.address.provinceId"
                        @change="handleChangeProvinces"
                        class="mr-2"
                        :allowClear="true"
                        show-search
                        :loading="dropdownData.provincesLoading"
                        optionFilterProp="search-key"
                        :placeholder="$t('common.province')"
                      >
                        <a-select-option
                          v-for="item in dropdownData.provinces"
                          :title="getLanguageName(item) + item.abbrCode"
                          :key="item.id"
                          :search-key="
                            item.cnName + item.enName + item.abbrCode
                          "
                        >
                          {{
                            getLanguageName(item) +
                            (item.abbrCode ? "(" + item.abbrCode + ")" : "")
                          }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="24" :sm="8" :md="6" :xl="4" :xxl="3">
                    <a-form-item :name="['address', 'cityId']" class="mb-0">
                      <a-select
                        v-model:value="data.address.cityId"
                        @change="handleChangeCity"
                        class="mr-2"
                        :allowClear="true"
                        show-search
                        :loading="dropdownData.cityLoading"
                        optionFilterProp="search-key"
                        :placeholder="$t('common.city')"
                      >
                        <a-select-option
                          v-for="item in dropdownData.city"
                          :title="getLanguageName(item)"
                          :key="item.id"
                          :search-key="item.cnName + item.enName"
                        >
                          {{ getLanguageName(item) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col
                    :span="24"
                    :sm="8"
                    :md="6"
                    :xl="4"
                    :xxl="3"
                    v-if="dropdownData.county && dropdownData.county.length > 0"
                  >
                    <a-form-item :name="['address', 'countyId']" class="mb-0">
                      <a-select
                        v-model:value="data.address.countyId"
                        class="mr-2"
                        :allowClear="true"
                        show-search
                        :loading="dropdownData.countyLoading"
                        optionFilterProp="search-key"
                        :placeholder="$t('common.county')"
                      >
                        <a-select-option
                          v-for="item in dropdownData.county"
                          :title="getLanguageName(item)"
                          :key="item.id"
                          :search-key="item.cnName + item.enName"
                        >
                          {{ getLanguageName(item) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            v-if="data.destinationType == destinationTypeEnum.OtherAddress"
          >
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :name="['address', 'address']"
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                :label="$t('warehouse.detail_address')"
              >
                <a-input v-model:value="data.address.address" v-address-filter :maxlength="35" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            v-if="data.destinationType == destinationTypeEnum.OtherAddress"
          >
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                :label="$t('warehouse.detail_address') + 2"
              >
                <a-input
                  v-model:value="data.address.address2"
                  v-address-filter
                  :maxlength="35"
                />
              </a-form-item>
            </a-col>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24, offset: 6 }"
              :xl="{ span: 9, offset: 3 }"
              :xxl="{ span: 8, offset: 2 }"
            >
              <a-form-item>
                <a-button
                  @click="handleSaveCommonAddress"
                  :loading="data.saveCommonAddressLoading"
                  size="small"
                >
                  <span v-if="!data.address.id">
                    {{ $t("warehouse.save_as_common_address") }}
                  </span>
                  <span v-else>
                    {{ $t("warehouse.refresh_address") }}
                  </span>
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- 其他地址 结束 -->

            <a-row v-if="data.warehouseType">
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label-col="{ span: 6 }"
                :wrapper-col="{
                  sm: { span: 10 },
                  xl: { span: 12 },
                }"
                name="sendoutType"
                :label="$t('warehouse.shipment_not_set')"
              >
                <a-select
                  v-model:value="data.sendoutType"
                  :placeholder="$t('common.please_select')"
                >
                  <a-select-option
                    v-for="(item,index) in sendoutTypeEnum"
                    :key="index"
                    :value="item"
                  >
                    {{ $t($enumLangkey("sendoutType", item)) }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

        </a-form>
      </div>

      <div>
        <a-row type="flex" justify="center">
          <a-col>
            <a-button class="ml-5" type="primary" @click="handleNextStep">{{
              $t("common.next_step")
            }}</a-button>
          </a-col>
        </a-row>
      </div>
    </template>
  </Content>
</template>

<script>
import { reactive, onMounted, ref, h } from "vue";
import Content from "../../components/Content.vue";
import {
  Row,
  Col,
  Steps,
  Input,
  Modal,
  Select,
  Button,
  Form,
  message,
} from "ant-design-vue";
import {
  destinationType as destinationTypeEnum,
  warehouseType as warehouseTypeEnum,
  sendoutType as sendoutTypeEnum,
} from "../../../enum/enum.json";
import { useI18n } from "vue-i18n/index";
import { getFbaOrFbmWarehouse } from "../../../api/modules/storage/index";
import {
  getWarehouseList,
  getSeWarehouseList,
} from "../../../api/modules/transit/outPlan/plan";

import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useTab } from "../../../hooks/tabs/index";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import {
  getAddressByLanguageV2,
  getLinkman,
  replaceBrackets,
  getName,
  verifyMobilePhoneNumber,
} from "../../../utils/general";
import {
  setObjectSessionStorage,
  getObjectSessionStorage,
  removeObjectSessionStorage,
} from "../../../utils/store";
import { transitOutPlanKeys } from "../../../consts/index";
import {
  getCommonAddress,
  createCommonAddress,
  updateCommonAddress,
  getGlobalCountrys,
} from "../../../api/modules/common/index";
import { useNewAddrHelper } from "../../../utils/addressHelper";
import CPostQuery from "../../components/CPostQuery.vue";

export default {
  name: "transit_out_plan_create",
  components: {
    CPostQuery,
    Content,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    AInputGroup: Input.Group,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute, visitedRoutes } = useTab();
    const refForm = ref();
    const { getters } = useStore();
    const addrHelper = useNewAddrHelper();

    const dropdownData = reactive({
      inventoryWarehouseLoading: false,
      inventoryWarehouses: [], //库存仓库列表
      targetWarehouses: [], //目标仓库列表.
      targetWarehousesLoading: false,
      commonAddress: [],
      commonAddressLoading: false,
      phoneCountryList: [],
      phoneCountryLoading: false,
    });

    const data = reactive({
      cacheWarehouseId: null, //缓存库存仓库id,用于判断缓存基础上有没有改变出库仓,要清空货件
      warehouseId: null, //库存仓库id
      inventoryWarehouseAddress: null, //库存仓库地址对象
      destinationType: null, //出库类型
      warehouseType: null, //仓库类型,选赛易仓库的时候
      toWarehouseId: null, //目标仓库代码
      targetAddress: null,
      sendoutType:null,//为谁发货
      address: {
        //companyName: null, //公司名称-目前没用到
        telephoneCountryId: null, //联系人的电话国家id ---保存常用地址用
        phoneCountryId: null, //联系人的电话国家id  ---创建计划时候用

        telephoneCountryTelCode: null, //联系人的电话区号 ---保存常用地址用
        phoneCountryCode: null, //联系人的电话区号 ---创建计划时候用

        telephone: null, //联系人的电话  ---保存常用地址用
        linkManPhone: null, //联系人的电话  ---创建计划时候用

        contactName: null, //公共地址的名称/发自
        linkMan: null, //联系人

        id: null, //公共地址的常用id
        countryId: null,
        provinceId: null,
        cityId: null,
        countyId: null,
        address: null,
        address2: null,
        postCode: null,
      },
    });

    const getAddress = (address) => {
      if (!address) {
        return;
      }
      return getAddressByLanguageV2(address, getters.language);
    };

    const _funcGetTargetWarehouse = () => {
      if (
        data.destinationType == destinationTypeEnum.FBA ||
        data.destinationType == destinationTypeEnum.mercadolibre
      ) {
        //platform 1 : 亚马逊, 2 : 美客多
        let platform = data.destinationType == destinationTypeEnum.FBA ? 1 : 2;
        dropdownData.targetWarehousesLoading = true;
        getFbaOrFbmWarehouse({ platform: platform })
          .then((res) => {
            if (res.result.length > 0) {
              dropdownData.targetWarehouses = (res.result || []).map((x) => {
                let address = [x.addressLine1, x.addressLine2]
                  .filter(Boolean)
                  .join(", ");
                return Object.assign({ address }, x);
              });
            }
            dropdownData.targetWarehousesLoading = false;
          })
          .catch((e) => {
            dropdownData.targetWarehousesLoading = false;
          });
      } else if (data.destinationType == destinationTypeEnum.OtherAddress) {
        handleGetCommonAddress();
        // 获得地址国家
        funcGetCountry();
      }
      else if (data.destinationType == destinationTypeEnum.SeWarehouse) {
             handleGetSeWarehouseList();
      }
    };

    const handelChangeOutType = () => {
      data.toWarehouseId = null;
      data.targetAddress = null;
      data.warehouseType = null;
      _funcGetTargetWarehouse();
    };

    const handelChangeSeWarehouseType = () => {
      data.toWarehouseId = null;
      data.targetAddress = null;
      handleGetSeWarehouseList();
    };

    const handleShowNoStockAlert = () => {
      Modal.confirm({
        title: vueI18n.t("warehouse.cannot_create_out_plan"),
        icon: h(ExclamationCircleOutlined),
        content: vueI18n.t("warehouse.we_no_have_your_goods_in_the_warehouse"),
        centered: true,
        mask: true,
        maskClosable: false,
        keyboard: false,
        okText: vueI18n.t("common.confirm"),
        //cancelText: "取消",
        cancelButtonProps: { style: { display: "none" } },
        onOk() {
          delVisitedRoute(router.currentRoute.value);
          router.push({ name: "transit_out_plan_list" });
        },
      });
    };

    const funcInventoryWarehouse = async () => {
      dropdownData.inventoryWarehouseLoading = true;
      let res = await getWarehouseList();
      dropdownData.inventoryWarehouseLoading = false;
      if (res?.result?.length > 0) {
        dropdownData.inventoryWarehouses = res.result;
      } else {
        dropdownData.inventoryWarehouses = [];
        handleShowNoStockAlert();
      }
    };

    const handleGetSeWarehouseList = () => {
      dropdownData.targetWarehousesLoading = true;
      getSeWarehouseList(data.warehouseType)
        .then(({ result }) => {
          if (result) {
            dropdownData.targetWarehouses = result;
          }
          dropdownData.targetWarehousesLoading = false;
        })
        .catch(() => {
          dropdownData.targetWarehousesLoading = false;
        });
    };

    const handelChangeInventoryWarehouse = (option, expand) => {
      data.inventoryWarehouseAddress = expand?.warehouseAddress;
    };

    const handelChangeToWarehouse = (option, expand) => {
      data.targetAddress = expand?.warehouseAddress;
    };

    const getCacheData = () => {
      let cacheData = getObjectSessionStorage(
        transitOutPlanKeys.createOutPlanData
      );
      if (cacheData) {
        Object.assign(data, cacheData);
        data.cacheWarehouseId = data.warehouseId;
        if (data.destinationType) {
          _funcGetTargetWarehouse();
        }
      }
    };

    //--------地址相关 开始--------------------
    const handleGetCommonAddress = () => {
      dropdownData.commonAddressLoading = true;
      getCommonAddress()
        .then(({ result }) => {
          if (result) {
            dropdownData.commonAddress = result;
          }
          dropdownData.commonAddressLoading = false;
        })
        .catch(() => {
          dropdownData.commonAddressLoading = false;
        });
    };

    const handleChangeCommonAddress = (value, option) => {
      if (!value) {
        return;
      }
      refForm.value?.clearValidate();
      Object.assign(data.address, option.option);
      data.address.phoneCountryId = data.address.telephoneCountryId;
      data.address.phoneCountryCode = data.address.telephoneCountryTelCode;
      data.address.linkManPhone = data.address.telephone;
      data.address.linkMan = data.address.contactName;
      handleChangeCountry();
    };

    //电话的国家区号
    const getCountryTelephoneAreaCode = () => {
      dropdownData.phoneCountryLoading = true;
      getGlobalCountrys()
        .then(({ result }) => {
          dropdownData.phoneCountryList = result;
          dropdownData.phoneCountryLoading = false;
        })
        .catch(() => {
          dropdownData.phoneCountryLoading = false;
        });
    };

    const handleChangeCountry = () => {
      funcGetProvinces();
    };

    const handleChangeProvinces = () => {
      funcGetCity();
    };

    const handleChangeCity = () => {
      funcGetCounty();
    };

    //地址的国家
    const funcGetCountry = () => {
      dropdownData.countryLoading = true;
      addrHelper
        .getCountries()
        .then((res) => {
          dropdownData.country = res;
          if (
            data.address.countryId &&
            dropdownData.country.findIndex(
              (x) => x.id == data.address.countryId
            ) == -1
          ) {
            data.address.countryId = null;
          }
          funcGetProvinces();
          dropdownData.countryLoading = false;
        })
        .catch(() => {
          dropdownData.countryLoading = false;
        });
    };

    const funcGetProvinces = async () => {
      dropdownData.provincesLoading = true;
      addrHelper
        .getProvinces(data.address.countryId)
        .then((res) => {
          dropdownData.provinces = res;
        })
        .finally(() => {
          dropdownData.provincesLoading = false;
          if (
            data.address.provinceId &&
            dropdownData.provinces.findIndex(
              (x) => x.id == data.address.provinceId
            ) == -1
          ) {
            data.address.provinceId = null;
          }
          funcGetCity();
        });
    };

    const funcGetCity = () => {
      dropdownData.cityLoading = true;
      addrHelper
        .getCities(data.address.provinceId)
        .then((result) => {
          dropdownData.city = result;
          if (
            data.address.cityId &&
            dropdownData.city.findIndex((x) => x.id == data.address.cityId) ==
              -1
          ) {
            data.address.cityId = null;
          }
          funcGetCounty();
          dropdownData.cityLoading = false;
        })
        .catch(() => {
          dropdownData.cityLoading = false;
        });
    };

    const funcGetCounty = () => {
      dropdownData.countyLoading = true;
      addrHelper
        .getCounties(data.address.cityId)
        .then((res) => {
          dropdownData.county = res;
        })
        .finally(() => {
          dropdownData.countyLoading = false;
          if (
            data.address.countyId &&
            dropdownData.county.findIndex(
              (x) => x.id == data.address.countyId
            ) == -1
          ) {
            data.address.countyId = null;
          }
        });
    };

    const handleSaveCommonAddress = () => {
      refForm.value
        .validateFields([
          ["address", "linkMan"],
          ["address", "linkManPhone"],
          ["address", "countryId"],
          ["address", "provinceId"],
          ["address", "cityId"],
          ["address", "address"],
          ["address", "postCode"],
        ])
        .then(() => {
          data.saveCommonAddressLoading = true;

          data.address.telephoneCountryId = data.address.phoneCountryId;
          data.address.telephone = data.address.linkManPhone;
          data.address.contactName = data.address.linkMan;

          if (data.address.id) {
            return updateCommonAddress({ ...data.address }).then(() => {
              message.success(vueI18n.t("common.succeed"));
              handleGetCommonAddress();
              data.saveCommonAddressLoading = false;
            });
          } else {
            return createCommonAddress({ ...data.address }).then((res) => {
              message.success(vueI18n.t("common.succeed"));
              handleGetCommonAddress();
              data.address.id = res.result;
              data.saveCommonAddressLoading = false;
            });
          }
        })
        .catch((err) => {
          data.saveCommonAddressLoading = false;
        });
    };

    const checkPhoneNumber = () => {
      data.address.linkManPhone = replaceBrackets(data.address.linkManPhone);
    };

    const handleChangePhoneCountryCode = (record, expand) => {
      data.address.phoneCountryCode = expand?.code ?? null;
      data.address.telephoneCountryTelCode = expand?.code ?? null; //这行代码预防bug出现
      handleChangePhone();
    };

    const handleChangePhone = () => {
      refForm.value
        .validateFields([["address", "linkManPhone"]])
        .then(() => {
          checkPhoneNumber();
        })
        .catch(() => {});
    };

    const getLanguageName = (item) => {
      if (item.countryCnName && item.countryEnName) {
        return getName(
          {
            cnName: item.countryCnName,
            enName: item.countryEnName,
          },
          getters.language
        );
      }
      return getName(item, getters.language);
    };

    // 检查邮编
    const postCodeQueryData = reactive({
      visible: false,
    });

    const postCodeQueryRef = ref(null);

    const handlePostCodeQuery = () => {
      postCodeQueryData.visible = true;
      if (
        postCodeQueryRef &&
        postCodeQueryRef.value &&
        postCodeQueryRef.value.handleQuery
      ) {
        postCodeQueryRef.value.handleQuery();
      }
    };

    const handleOnPostQuerySelected = (record) => {
      if (data.address) {
        data.address.countryId = record.countryId;
        data.address.provinceId = record.provinceId;
        data.address.cityId = record.cityId;
        data.address.countyId = record.countyId;
        data.address.postCode = record.postCode;
        if (record.address) {
          data.address.address = record.address;
        }
        funcGetCountry();
      }
    };

    //--------地址相关 结束--------------------

    const handleNextStep = async () => {
      refForm.value
        .validate()
        .then(() => {
          if (data.cacheWarehouseId != data.warehouseId) {
            data.list=[];//第二部的货件列表
          }
          setObjectSessionStorage(transitOutPlanKeys.createOutPlanData, data);
          delVisitedRoute(router.currentRoute.value);
          router.push({ name: "transit_out_plan_add_shipment" });
        })
        .catch((res) => {});
    };

    onBeforeRouteLeave((to, from, next) => {
      const cacheRoutes = [
        "/transit/outplan/create",
        "/transit/outplan/addshipment",
      ];
      if (!cacheRoutes.includes(to.path)) {
        let tabsHasOrderRoute =
          visitedRoutes.value.findIndex((x) => cacheRoutes.includes(x.path)) >
          -1;
        if (!tabsHasOrderRoute) {
          removeObjectSessionStorage(transitOutPlanKeys.createOutPlanData);
        }
      }
      next();
    });

    const rules = {
      warehouseId: [
        {
          required: true,
          //trigger: ['change', 'blur'],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.inventory_affiliation_warehouse"),
            ]);
          },
        },
      ],
      destinationType: [
        {
          required: true,
          type: "integer",
          //trigger: ['change', 'blur'],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.out_warehouse_type"),
            ]);
          },
        },
      ],
      warehouseType: [
        {
          required: true,
          type: "integer",
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.warehouse_type"),
            ]);
          },
        },
      ],
      toWarehouseId: [
        {
          required: true,
          // trigger: ['change', 'blur'],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.warehouse_code"),
            ]);
          },
        },
      ],
       sendoutType: [
        {
          required: true,
          // trigger: ['change', 'blur'],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.shipment_not_set"),
            ]);
          },
        },
      ],
      address: {
        linkMan: [
          {
            required: true,
            message: () =>
              vueI18n.t("common.p0_is_required", [
                vueI18n.t("warehouse.destination"),
              ]),
          },
        ],
        countryId: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            message: () => {
              return vueI18n.t("common.p0_is_not_complete", [
                vueI18n.t("common.country"),
              ]);
            },
          },
        ],
        provinceId: [
          {
            required: true,
            whitespace: true,
            validator: (rule, value) => {
              if (
                dropdownData.provinces?.length > 0 &&
                !data.address.provinceId
              ) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
            trigger: ["change", "blur"],
            message: () => {
              return vueI18n.t("common.p0_is_not_complete", [
                vueI18n.t("common.province"),
              ]);
            },
          },
        ],
        cityId: [
          {
            required: true,
            whitespace: true,
            validator: (rule, value) => {
              if (dropdownData.city?.length > 0 && !data.address.cityId) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
            trigger: ["change", "blur"],
            message: () => {
              return vueI18n.t("common.p0_is_not_complete", [
                vueI18n.t("common.city"),
              ]);
            },
          },
        ],
        linkManPhone: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            validator: (rule, value) => {
              if (!data.address.phoneCountryId) {
                return Promise.reject(
                  vueI18n.t("common.p0_is_required", [
                    vueI18n.t("logistics.area_code"),
                  ])
                );
              }
              if (!value) {
                return Promise.reject(
                  vueI18n.t("common.p0_is_required", [
                    vueI18n.t("warehouse.phone"),
                  ])
                );
              }
              let selectCountry = dropdownData.phoneCountryList.find(
                (item) => item.id === data.address.phoneCountryId
              );
              let countryIos2Code = selectCountry?.ioS2 ?? null;
              if (
                verifyMobilePhoneNumber(
                  countryIos2Code,
                  (selectCountry?.telCode ?? "") + value
                )
              ) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  vueI18n.t("common.p0_incorrect_format", [
                    vueI18n.t("warehouse.phone"),
                  ])
                );
              }
            },
          },
        ],

        address: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            validator: (rule, value) => {
              if (!value) {
                return Promise.reject(
                  vueI18n.t("common.p0_is_required", [
                    vueI18n.t("warehouse.detail_address"),
                  ])
                );
              }
              if (value && value?.length > 35) {
                return Promise.reject(
                  vueI18n.t("common.the_input_length_is_too_long")
                );
              }

              return Promise.resolve();
            },
          },
        ],
        postCode: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            validator: (rule, value) => {
          if (!value||value.trim().length == 0) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("common.postcode")]));
          }

          if (value && value?.trim().length < 5) {
            return Promise.reject(vueI18n.t("common.p0_please_enter_at_least_p1_digits", [vueI18n.t("common.postcode"),5]));
          }
          return Promise.resolve();
        },
          },
        ],
      },
    };

    getCacheData();
    onMounted(async () => {
      funcInventoryWarehouse();
      getCountryTelephoneAreaCode();
    });

    return {
      rules,
      refForm,
      destinationTypeEnum,
      warehouseTypeEnum,
      sendoutTypeEnum,
      dropdownData,
      data,

      getAddress,
      getLinkman,

      handelChangeOutType,
      handelChangeSeWarehouseType,
      handelChangeInventoryWarehouse,
      handelChangeToWarehouse,

      handleChangeCountry,
      handleChangeProvinces,
      handleChangeCity,
      funcGetCountry,
      funcGetProvinces,
      funcGetCity,
      funcGetCounty,

      handleChangeCommonAddress,
      handleSaveCommonAddress,
      handleChangePhone,
      handleChangePhoneCountryCode,
      getLanguageName,
      postCodeQueryData,
      postCodeQueryRef,
      handlePostCodeQuery,
      handleOnPostQuerySelected,

      handleNextStep,
    };
  },
};
</script>

<style lang="less" scoped></style>
